
export default {
  props: {
    /**
     * Token 0 Logo
     * @type String
     */
    token0: {
      type: String,
      required: true,
    },
    /**
     * Token 1 Logo
     * @type String
     */
    token1: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      logoToFix: ['https://www.unbound.finance/img/logo-icon.svg'],
    }
  },
}
