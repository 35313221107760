
import { Web3Provider } from '@ethersproject/providers'
import { Vue, Component, Watch } from 'vue-property-decorator'
import VueSlickCarousel from 'vue-slick-carousel'
import { mapActions } from 'vuex'

import { dexes } from '~/components/DexSelectMenu.vue'
import { ITEMS_PER_TABLE } from '~/constants'
import { CHAIN_ICONS } from '~/constants/chainConfigs'
import {
  rewardInfo,
  getStrategiesAddress,
  getRewardInfo,
} from '~/constants/liquidityMining'
import statsQuery from '~/graphql/stats/index.gql'
import strategiesQuery from '~/graphql/strategies/index.gql'
import { getCamelotPoolMap } from '~/services/camelot'
import { getStrategyMap } from '~/services/merkl'
import { web3Store } from '~/store'
import { Pool as CamelotPool } from '~/types/camelot'
import { Pool } from '~/types/merkl'
import { Dex, Network, NetworkEnum } from '~/types/web3'
import { filterNonNull, isClient } from '~/utils'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

const aloStrategyMap: Partial<Record<Dex, Partial<Record<Network, string[]>>>> =
  {
    [Dex.Sushiswap]: {
      [NetworkEnum.arbitrum]: [
        '0x60b32fd699623ead58ae911660012dc3475ba0a7',
        '0x55736c3470becc75d90a7811278d8d41f48ee219',
        '0x7e0ce62d06a6d9ff05c35b39ab3578a20478bee3',
      ],
    },
    [Dex.Pancakeswap]: {
      [NetworkEnum.bsc]: [
        '0xca2f7d0307b48dec9a88f5b8e2464bce40b8aeb1',
        '0x71fbe0b0d7ae850a7b48925efffa3fcb654fa3cb',
        '0x2082e4c4f5490e5d8989878c9154683d350dc06e',
      ],
    },
    [Dex.Uniswap]: {
      [NetworkEnum.polygon]: [
        '0xca57b794942de3dfb975f689b8108d326b503e24',
        '0xb3cbbd72117722bfac2506c1af8d61f24e7c5826',
        '0xc79f5e8804b57eff9b26c5ad2c40cd837d53dad6',
      ],
    },
    [Dex.Arbidex]: {
      [NetworkEnum.arbitrum]: ['0xcf8ea96ecfdb7800e1eb413bde4af6ce984ab079'],
    },
  }

type Alert = {
  logo?: string
  text: string
  active: boolean
  buttonText?: string
  props: { to?: string; href?: string; target?: string }
  is?: 'a'
  on?: any
}

// eslint-disable-next-line no-use-before-define
@Component<Dashboard>({
  components: { VueSlickCarousel },
  head() {
    return {
      title: '',
      titleTemplate: 'DefiEdge',
    }
  },
  data() {
    return {
      rewardInfo: getRewardInfo(),
      web3Store,
      CHAIN_ICONS,
    }
  },
  apollo: {
    strategyList: {
      prefetch: true,
      client: 'metadata',
      query: strategiesQuery,
      variables() {
        return {
          strategyListNetworks: [web3Store.network],
          strategyListOrder: this.table.direction,
          strategyListSortBy: this.table.column,
          strategyListTake: ITEMS_PER_TABLE,
          strategyListPage: this.page,
          strategyListSearch: this.table.search,
          strategyListType: this.table.type,
          strategyReturnInToken: this.table.returnInToken,
          strategyListAddresses: this.table.addresses,
          dexes: [web3Store.dex],
        }
      },
      skip() {
        return !this.web3Store.network
      },
    },
    stats: {
      prefetch: true,
      client: 'metadata',
      query: statsQuery,
      variables() {
        const dex: Dex[] =
          this.dex === 'All'
            ? dexes.map((e) => (e === 'PancakeSwap' ? Dex.Pancakeswap : e))
            : [this.dex]

        return {
          network: web3Store.chainConfigs.map((e) => e.network),
          dex,
          manager: null,
        }
      },
    },
  },
  mounted() {
    this.init()
    this.$root.$on('login_complete', this.init)
  },
  beforeDestroy() {
    this.$root.$off('login_complete', this.init)
  },
  methods: {
    ...mapActions({
      fetchEntries: 'strategy/fetchEntries',
    }),
  },
})
export default class Dashboard extends Vue {
  accessPermitted = false
  rewardInfo!: ReturnType<typeof getRewardInfo>
  web3Store = web3Store
  paused = false
  strategyList = {
    data: [],
    totalPages: 0,
  }

  ready = false
  currentSlide = 0
  alerts: Alert[] = []

  dex: Dex | 'All' = 'All'
  strategyMap: Record<
    string,
    Pool & {
      strategy?: string | undefined
      hasRewards?: boolean | undefined
    }
  > = {}

  strategyMapCamelot: Record<string, CamelotPool> = {}

  get tableKey(): string {
    return Object.entries(this.table)
      .map(([a, b]) => `${a}=${b}`)
      .join(',')
  }

  table = {
    column: 'aum',
    direction: 'desc',
    search: '',
    addresses: undefined,
    type: null,
    returnInToken: null,
  }

  page = 1

  get isLoading() {
    return this.$apollo.queries.strategyList.loading
  }

  get pageCount() {
    return this.strategyList.totalPages
  }

  get currentDex() {
    return this.web3Store.dex
  }

  get allNetworkFromDex() {
    return web3Store.chainConfigs.map((e) => e.network)
  }

  hasNetwork(network: Network) {
    return this.allNetworkFromDex.includes(network)
  }

  @Watch('table', { deep: true })
  updateTable() {
    this.page = 1

    this.fetchEntries()
  }

  @Watch('page')
  fetchEntries() {
    this.$apollo.queries.strategyList.refresh()
  }

  paginateHandler(pageNum: number) {
    this.page = pageNum
  }

  get lmStrategies() {
    return getStrategiesAddress(this.chainId)
  }

  get chainId() {
    return this.web3Store.status.chainId!
  }

  get types() {
    return [
      { type: 'all', currency: null },
      { type: 'stable', currency: 'USD' },
      { type: 'watch', currency: undefined },
      // /(polygon)|(matic)/.exec(this.web3Store.network ?? '')
      //   ? { currency: 'MATIC', type: 'matic' }
      //   : { currency: 'ETH', type: 'eth' },
      ...(this.lmStrategies.length
        ? [
            {
              type: 'Farming',
              value: 'LA',
              currency: undefined,
            },
          ]
        : []),
      ...(web3Store.currentChain?.network &&
      aloStrategyMap[web3Store.dex]?.[web3Store.currentChain?.network]?.length
        ? [
            {
              type: 'ALO',
              value: 'ALO',
              currency: undefined,
              //     tooltip: {
              //       content: `
              //   <div class='border bg-primary-2 border-primary-3 rounded-[4px] text-xs text-black py-2 px-3'>
              //     Strategy are auto rebalanced.
              //   </div>
              // `.trim(),
              //     },
            },
          ]
        : []),
      // {
      //   type: '🔰 LA',
      //   value: 'LA',
      //   currency: null,
      //   tooltip: {
      //     delay: { show: 0, hide: 1000 },
      //     content: `
      //       <div class='border bg-primary-2 border-primary-3 rounded-[4px] text-xs text-black py-2 px-3'>
      //         <a href="https://defiedge.io/liquidityalliance" target="_blank">Learn More</a>
      //       </div>
      //     `
      //       .trim()
      //       .replace(/\s\s/gi, ''),
      //   },
      // },
      // {
      //   type: 'Auto rebalance',
      //   value: 'AR',
      //   currency: null,
      //   tooltip: {
      //     content: `
      //       <div class='border bg-primary-2 border-primary-3 rounded-[4px] text-xs text-black py-2 px-3'>
      //         Strategy are auto rebalanced.
      //       </div>
      //     `.trim(),
      //   },
      // },
    ]
  }

  @Watch('currentDex')
  setBanners() {
    this.rewardInfo = getRewardInfo()
    this.alerts = [
      {
        active: true,
        buttonText: 'Sneak Peek',
        flashy: true,
        logo: 'https://cdn-icons-png.flaticon.com/512/559/559384.png',
        props: {
          target: '_blank',
          href: 'https://twitter.com/DefiEdge/status/1702341961007739234',
          is: 'a',
        },
        text: '<span class="font-semibold capitalize">Aesthetic Upgrade Alert:</span> A sleeker, smarter, and more user-friendly interface awaits!',
      },
      ...(this.currentDex === Dex.Uniswap
        ? [
            {
              active: false,
              props: {
                to: `/s/optimism/0xd1c33d0af58eb7403f7c01b21307713aa18b29d3`,
              },
              buttonText: 'Check out',
              logo: 'https://assets.coingecko.com/coins/images/25757/large/USD__logo.png?1653519267',
              text: `OP Rewards Available In <span class="font-semibold capitalize">OverNight strategy</span>!`,
            },
          ]
        : []),
      ...Object.values(rewardInfo)
        .filter(filterNonNull)
        .flat()
        .filter((e) => !!e.active)
        .map(
          (e): Alert => ({
            ...e,
            active: !!e.active,
            ...(e.dexes.includes(this.currentDex)
              ? { props: { to: `/farm?network=${e.network}` } }
              : this.isLocalChange
              ? {
                  props: { to: `/farm?network=${e.network}` },
                  on: { click: () => this.select(e.dexes[0]) },
                }
              : {
                  props: {
                    target: '_blank',
                    href: `${this.getDexUrl(e.dexes[0])}/farm?network=${
                      e.network
                    }`,
                  },
                  is: 'a',
                }),
            buttonText: 'LM rewards',
            text: `${e.name} Rewards Live On <span class="font-semibold capitalize">${e.network}</span>!`,
          })
        ),
    ].filter((e) => !!e.active)
  }

  get isLocalChange() {
    // if (isStaging) return true
    if (!isClient) return false

    return (
      window.location.hostname.includes('staging') ||
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('herokuapp')
    )
  }

  getDexUrl(dex: Dex | 'PancakeSwap') {
    return (
      window.location.protocol +
      '//' +
      (dex === Dex.Uniswap
        ? 'app'
        : dex === 'PancakeSwap' || dex === Dex.Pancakeswap
        ? 'pancakeswap'
        : dex
      ).toLowerCase() +
      '.' +
      window.location.hostname.split('.').slice(1).join('.')
    )
  }

  select(dex: Dex | 'PancakeSwap') {
    if (dex === this.currentDex) return

    web3Store.setDex(dex === 'PancakeSwap' ? Dex.Pancakeswap : dex)
  }

  async init() {
    this.setBanners()

    web3Store
      .accessPermitted(this.$root.web3Provider as Web3Provider)
      .then((result) => (this.accessPermitted = result))
      .catch(console.log)

    try {
      const result = await Promise.allSettled([
        getStrategyMap(web3Store.status.chainId!),
        getCamelotPoolMap(),
      ])

      this.strategyMap =
        result[0].status === 'fulfilled' ? result[0].value.data : {}
      this.strategyMapCamelot =
        result[1].status === 'fulfilled' ? result[1].value.data : {}
    } catch (e: any) {
      console.log(e)
    }

    return this.fetchEntries()
  }
}
