var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('LoadingProgress',_vm._b({},'LoadingProgress',{
    indeterminate: _vm.indeterminate,
    counterClockwise: _vm.counterClockwise,
    hideBackground: _vm.hideBackground,
    progress: _vm.progress,
    size: _vm.size,
    shape: _vm.shape,
    rotate: _vm.rotate,
    fillDuration: _vm.fillDuration,
    rotationDuration: _vm.rotationDuration,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }