
import { Vue, Component, Prop } from 'vue-property-decorator'

// eslint-disable-next-line no-use-before-define
@Component<SkeletonTable>({})
export default class SkeletonTable extends Vue {
  @Prop({ type: Array, required: true }) columns: any
  @Prop({ type: Boolean, default: false })
  hideSearch!: boolean

  @Prop({ type: Boolean, default: false }) leaderboard!: boolean
  @Prop({ type: Number , required: true,default :0 }) types! : number
}
