
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ProgressLoader extends Vue {
  @Prop({ type: Boolean }) indeterminate!: boolean
  @Prop({ type: Boolean }) counterClockwise!: boolean
  @Prop({ type: Boolean }) hideBackground!: boolean
  @Prop({ type: Boolean }) rotate!: boolean
  @Prop({ type: [Number, String] }) progress!: number | string
  @Prop({ type: String }) color!: string
  @Prop({ type: String }) shape!: string
  @Prop({ type: [Number, String] }) fillDuration!: number | string
  @Prop({ type: [Number, String] }) size!: number | string
  @Prop({ type: [Number, String] }) width!: number | string
  @Prop({ type: [Number, String] }) rotationDuration!: number | string

  @Watch('width', { immediate: true })
  setColor() {
    this.$nextTick(() => {
      const svg = (this.$el as HTMLDivElement).firstElementChild as HTMLElement
      svg?.setAttribute('preserveAspectRatio', 'none')
      if (this.width)
        svg?.querySelectorAll('path').forEach((e) => {
          e.style.strokeWidth = this.width.toString()
        })
    })
  }
}
