import { render, staticRenderFns } from "./index.vue?vue&type=template&id=546bf286&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=546bf286&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SafeImage: require('/tmp/build_7e4316b3/components/SafeImage.vue').default,NeutralButton: require('/tmp/build_7e4316b3/components/NeutralButton.vue').default,DashboardStats: require('/tmp/build_7e4316b3/components/DashboardStats.vue').default,StrategyTable: require('/tmp/build_7e4316b3/components/Tables/StrategyTable.vue').default})
