
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

import { CHAIN_ICONS, CHAIN_COLORS } from '~/constants/chainConfigs'
import { getStrategiesAddress } from '~/constants/liquidityMining'
import { web3Store } from '~/store'
import { Dex } from '~/types/web3'
import { getChainId } from '~/utils'

@Component
export default class extends Vue {
  @Prop({ type: Object, required: true })
  stats!: any

  @Prop({ type: Boolean, required: false, default: false })
  loading!: boolean

  @VModel({ type: String, required: false })
  dex!: Dex | 'All'

  web3Store = web3Store
  tooltipOptions = {
    formatTooltipX: (d: any) => (d + '').toUpperCase(),
    formatTooltipY: (d: any) => '$' + d,
  }

  tabViews = {
    values: ['Volume', 'Fees'],
    currentView: 0,
  }

  get statSubItems() {
    if (!this.stats) return []

    return [
      {
        text: 'Strategies\nCreated',
        value: this.stats.totalStrategies,
      },
      {
        text: `${this.dex === 'All' ? '' : `${web3Store.dex}\n`}Pools`,
        value: this.stats.poolCount,
      },
      {
        text: `Active\nPositions`,
        value: this.stats.rangesCount,
      },
      {
        text: `Active\nTokens`,
        value: this.stats.assetCount,
      },
      {
        text: `Liquidity\nProviders`,
        value: this.stats.uniqueDepositor,
      },
    ]
  }

  get hasLiquidMining() {
    const chainId =
      web3Store.status.chainId ?? web3Store.chainConfigs[0].chainId
    return !!getStrategiesAddress(chainId).length
  }

  get allNetworks() {
    return web3Store.chainConfigs
      .map((e) => e.network.replace('mainnet', 'ethereum'))
      .map((e) => {
        const network = e.replace('ethereum', 'mainnet')
        const chainId = getChainId(network)

        return {
          chainId,
          network,
          name: e.replace('bsc', 'BNB Chain'),
          icon: (CHAIN_ICONS as any)[chainId],
          color: (CHAIN_COLORS as any)[chainId],
        }
      })
  }

  get statItems() {
    if (!this.stats) return []
    return [
      {
        text: 'TVL\nManaged',
        value: this.stats.totalValueManaged,
        chartData: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value:
            +Number(
              (this.stats.aumSummary[network] / this.stats.totalValueManaged) *
                100
            ) || 0,
        })),
        breakdown: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value: +this.stats.aumSummary[network] || 0,
        })),
      },
      {
        text: `Fees\nGenerated`,
        value: this.stats.totalFees,
        chartData: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value:
            +Number(
              (this.stats.fees[network] / this.stats.totalFees) * 100
            ).toFixed(6) || 0,
        })),
        breakdown: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value: +this.stats.fees[network] || 0,
        })),
      },
      {
        text: `Volume`,
        value: this.stats.totalVolume,
        chartData: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value:
            +Number(
              (this.stats.volumeSummary[network] / this.stats.totalVolume) * 100
            ).toFixed(6) || 0,
        })),
        breakdown: this.allNetworks.map(({ network, ...a }) => ({
          ...a,
          value: +this.stats.volumeSummary[network] || 0,
        })),
      },
    ]
  }
}
