// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/rank_others.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/rank_first.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/rank_second.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rank[data-v-22089617]{align-items:center;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:contain;color:#fff;display:inline-flex;font-family:Nunito,sans-serif;font-size:13px;font-style:normal;font-weight:900;height:28px;justify-content:center;line-height:160%;text-shadow:0 1px 4px rgba(0,0,0,.35);width:28px}.rank.idx-1[data-v-22089617]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.rank.idx-2[data-v-22089617]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
