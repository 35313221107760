import { render, staticRenderFns } from "./StrategyTable.vue?vue&type=template&id=3b1aeb5a&scoped=true&"
import script from "./StrategyTable.vue?vue&type=script&lang=ts&"
export * from "./StrategyTable.vue?vue&type=script&lang=ts&"
import style0 from "./StrategyTable.vue?vue&type=style&index=0&id=3b1aeb5a&prod&lang=scss&scoped=true&"
import style1 from "./StrategyTable.vue?vue&type=style&index=1&id=3b1aeb5a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b1aeb5a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonTable: require('/tmp/build_7e4316b3/components/Skeletons/SkeletonTable.vue').default,ChevronRightIcon: require('/tmp/build_7e4316b3/components/Icons/ChevronRightIcon.vue').default,StarButton: require('/tmp/build_7e4316b3/components/Tables/StarButton.vue').default,RankNumber: require('/tmp/build_7e4316b3/components/RankNumber.vue').default,DoubleLogo: require('/tmp/build_7e4316b3/components/DoubleLogo.vue').default,ManagerIcon: require('/tmp/build_7e4316b3/components/Icons/ManagerIcon.vue').default,OpenInNewIcon: require('/tmp/build_7e4316b3/components/Icons/OpenInNewIcon.vue').default,InfoCircleIcon: require('/tmp/build_7e4316b3/components/Icons/InfoCircleIcon.vue').default,Badge: require('/tmp/build_7e4316b3/components/Badge.vue').default})
