import {
  Distributor__factory,
  MerklAPIData,
  registry,
} from '@angleprotocol/sdk'
import { JsonRpcSigner } from '@ethersproject/providers'

import api from '~/api'
import { Pool, Root } from '~/types/merkl'

export const claim = async (
  chainId: number,
  signer: JsonRpcSigner,
  _tokens: string[]
) => {
  let data: MerklAPIData['transactionData']

  try {
    data = (
      await api.merkl(`/v1/merkl?chainId=${chainId}&user=${signer._address}`, {
        timeout: 5000,
      })
    ).data.transactionData
  } catch {
    throw new Error('Angle API not responding')
  }

  if (!data) throw new Error('Empty response')

  const tokens = Object.keys(data)
    .filter((k) => data![k].proof !== undefined)
    .filter((e) => _tokens.includes(e))

  const claims = tokens.map((t) => data![t].claim)
  const proofs = tokens.map((t) => data![t].proof)

  const contractAddress = registry(chainId)?.Merkl?.Distributor

  if (!contractAddress) throw new Error('Chain not supported')

  const contract = Distributor__factory.connect(contractAddress, signer)

  return contract.claim(
    tokens.map(() => signer._address),
    tokens,
    claims,
    proofs as string[][]
  )
}

let cache = {
  data: {} as Record<
    string,
    Pool & { strategy?: string; hasRewards?: boolean }
  >,
  date: 0,
  chainId: -1,
}

export async function getStrategyMap(chainId: number, walletAddress?: string) {
  if (
    Date.now() - cache.date < 5 * 60 * 1000 /* 5min */ &&
    cache.chainId === chainId
  ) {
    return cache
  }

  const result = await api.merkl(
    `/v1/merkl?${
      walletAddress ? `user=${walletAddress.toLowerCase()}&` : ''
    }chainId=${chainId}`,
    { timeout: 15000 }
  )

  return (cache = Object.values((result.data as unknown as Root).pools)
    .filter((e_1) => e_1.chainId === chainId)
    .map((e_2) => ({
      ...e_2,
      hasRewards: !!Object.values(e_2.rewardsPerToken).filter(
        (e_3) => !!e_3.unclaimed
      ).length,
      strategy: e_2.almDetails.find((e_4) =>
        e_4.label?.toLowerCase().includes('defiedge')
      )?.address,
    }))
    .filter((e_5) => !!e_5.strategy)
    .reduce(
      (map, e) => {
        map.data[e.strategy?.toLowerCase() ?? Object.keys(map.data).length] = e
        return map
      },
      { data: {}, chainId, date: Date.now() } as typeof cache
    ))
}
