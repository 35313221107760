var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"bg-[#fcfcfc] py-8 dark:bg-black-1"},[_c('div',{staticClass:"mx-auto max-w-7xl px-3 sm:px-6 lg:px-8"},[(_vm.alerts.length)?_c('div',{staticClass:"flex mb-6 pr-1 gap-x-3 items-center select-none"},[_c(_vm.alerts.length > 1 ? 'VueSlickCarousel' : 'div',{ref:"carousel",tag:"component",staticClass:"flex-1 -m-2",attrs:{"arrows":false,"autoplay":"","autoplay-speed":10000,"dots":false,"draggable":true,"slides-to-show":1,"vertical":""},on:{"beforeChange":(_, b) => (_vm.currentSlide = b),"init":function($event){_vm.ready = true}}},_vm._l((_vm.alerts),function(alert,i){return _c('div',{key:i,staticClass:"p-2"},[_c(alert.is || 'NuxtLink',_vm._b({tag:"component",attrs:{"custom":""}},'component',alert.props,false),[_c(alert.is ? 'div' : 'a',_vm._g({tag:"component",staticClass:"h-[65px] block relative sm:h-auto"},alert.on),[_c('div',{staticClass:"bg-white border flex h-full border-primary-2 rounded-2xl py-3 px-4 gap-x-3 items-center md:px-6 dark:bg-grey-10 dark:border-grey-8 dark:border-opacity-50",class:{
                    'border-primary-1 !border-opacity-100': alert.flashy,
                  }},[_c('SafeImage',{staticClass:"h-6",class:{ 'animate-pulse': alert.flashy },attrs:{"src":alert.logo}}),_vm._v(" "),_c('span',{staticClass:"text-grey-6 line-clamp-2 dark:text-grey-2",domProps:{"innerHTML":_vm._s(alert.text)}}),_vm._v(" "),(alert.buttonText)?_c('NeutralButton',{staticClass:"whitespace-nowrap hidden sm:block !ml-auto"},[_vm._v(_vm._s(alert.buttonText))]):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"rounded-2xl inset-0 animate-pulse ring-2 ring-primary-2 ring-opacity-30 absolute pointer-events-none"})])],1)],1)}),0),_vm._v(" "),(_vm.alerts.length > 1)?_c('div',{staticClass:"flex flex-col space-y-1",staticStyle:{"height":"58px"}},_vm._l((_vm.alerts.length),function(i){return _c('div',{key:i,staticClass:"dot",class:[{ paused: _vm.paused, active: _vm.currentSlide == i - 1 && _vm.ready }],style:({
              fontSize: '1px',
              height: `calc(${100 / _vm.alerts.length}% - 4px)`,
            }),on:{"click":function($event){return _vm.$refs.carousel.goTo(i - 1)}}},[_vm._v("\n             \n          ")])}),0):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ClientOnly',[_c('DashboardStats',_vm._b({model:{value:(_vm.dex),callback:function ($$v) {_vm.dex=$$v},expression:"dex"}},'DashboardStats',{ stats: _vm.stats, loading: _vm.$apollo.queries.stats.loading },false))],1)],1)]),_vm._v(" "),_c('div',{staticClass:"mx-auto max-w-7xl px-3 pb-10 sm:px-6 lg:px-8 dark:text-gray-50"},[_c('main',{staticClass:"mt-4 md:mt-10"},[_c('ClientOnly',[_c('div',[_c('StrategyTable',_vm._b({staticClass:"pb-0",attrs:{"config":_vm.table,"types":_vm.types},on:{"update:config":function($event){_vm.table=$event}}},'StrategyTable',{
              isLoading: _vm.isLoading,
              strategyMap: _vm.strategyMap,
              strategyMapCamelot: _vm.strategyMapCamelot,
              data: _vm.strategyList,
            },false)),_vm._v(" "),(_vm.pageCount > 1)?_c('div',{staticClass:"flex mt-6 items-center justify-center md:justify-end"},[_c('Paginate',{attrs:{"break-view-class":"break-view","break-view-link-class":"break-view-link","container-class":"pagination ","margin-pages":2,"next-class":"next-item","next-link-class":"next-link-item !border-0 !border-l rounded-r-lg","next-text":">","page-class":"page-item","page-count":_vm.pageCount,"page-link-class":"page-link !border-0 hover:shadow-lg","prev-class":"prev-item border-0","prev-link-class":"prev-link-item !border-0 !border-r-2 rounded-l-lg","prev-text":"<"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }