
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CompactNumber extends Vue {
  @Prop({ type: [Number, String], required: true }) value!: any
  @Prop({ type: String, default: '' }) prefix!: string
  @Prop({ type: Array, default: () => [] }) args: any
  @Prop({ type: Boolean, default: false }) showDash!: boolean
  @Prop({ type: [Number, String], required: false, default: null }) threshold!:
    | number
    | string
}
