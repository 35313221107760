var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-white border border-primary-2 rounded-2xl p-3 md:py-7 md:px-6 dark:bg-grey-10 dark:border-grey-8 dark:border-opacity-50"},[_c('div',{staticClass:"flex font-medium items-center"},[_c('span',{staticClass:"text-base text-black-static leading-5 whitespace-pre-wrap dark:text-white"},[_vm._v(_vm._s(_vm.statItem.text))]),_vm._v(" "),_c('span',{staticClass:"flex-1 font-primary tracking-tight text-right text-2xl text-grey-7 text-[22px] md:text-[42px] md:leading-[102%] dark:text-grey-4"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.statItem.value > 1000
            ? `$${Number(_vm.statItem.value).toLocaleString('en-us')}`
            : undefined
        ),expression:"\n          statItem.value > 1000\n            ? `$${Number(statItem.value).toLocaleString('en-us')}`\n            : undefined\n        "}],class:{ 'hover:cursor-help': _vm.statItem.value > 1000 },domProps:{"innerHTML":_vm._s(_vm.abbreviate(_vm.statItem.value))}})])]),_vm._v(" "),_c('div',{staticClass:"space-x-1 my-5 w-full hidden md:flex"},_vm._l((_vm.statItem.chartData),function({ name, value, color }){return _c('div',{key:name,staticClass:"rounded-full h-2 transition-colors",style:({
        width: `${value}%`,
        backgroundColor: `${color}${_vm.hovered == name ? '' : '4D'}`,
      }),on:{"mouseenter":function($event){_vm.hovered = name},"mouseleave":function($event){_vm.hovered = null}}})}),0),_vm._v(" "),_c('div',{staticClass:"space-y-2 text-xs hidden md:block"},_vm._l((_vm.statItem.breakdown),function({ name, icon, value, color }){return _c('div',{key:name,staticClass:"border-transparent border rounded-full flex font-medium space-x-2 text-sm text-black-mid w-full p-1.5 pr-4 transition-colors leading-p160 capitalize items-center dark:text-white dark:text-opacity-70",style:({
        backgroundColor: `${color}${_vm.hovered == name ? '33' : '1A'}`,
        borderColor: _vm.hovered == name ? color : '',
      }),on:{"mouseenter":function($event){_vm.hovered = name},"mouseleave":function($event){_vm.hovered = null}}},[_c('img',{staticClass:"object-contain h-6 w-6",attrs:{"src":icon}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(name)+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"flex-1 text-right"},[_c('CompactNumber',{attrs:{"prefix":"$","value":value}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }