
import { web3Store } from '~/store'
import { getChainId } from '~/utils'

export default {
  props: {
    address: { type: String, required: true },
    network: { type: String, required: true },
  },
  data() {
    return {
      web3Store,
    }
  },
  computed: {
    chainId() {
      return this.network
        ? getChainId(this.network)
        : this.web3Store?.status.chainId
    },
    model() {
      return this.$store.state.app?.watchListMap[this.chainId]?.includes(
        this.address
      )
    },
  },
  watch: {
    '$store.state.app.watchListMap': {
      deep: true,
      handler() {
        this.model = this.$store.state.app?.watchListMap[
          this.chainId
        ]?.includes(this.address)
      },
    },
  },
}
