
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { toFixed } from '~/plugins/filters'

@Component({
  props: {},
})
export default class extends Vue {
  @Prop({ type: [Boolean, Object], default: false })
  hasLiquidityMining!: boolean

  @Prop({ type: Boolean })
  hideArrow!: boolean

  @Prop({ type: Number, required: true })
  value!: number

  @Prop({ type: Number, required: false, default: null })
  lpValue!: number

  @Prop({ type: Number, required: false, default: null })
  feeApy!: number

  @Prop({ type: String, required: false, default: null })
  feeApyText!: number

  @Prop({ type: String, required: false, default: null })
  valueText!: string

  @Prop({ type: String, required: false, default: null })
  lpText!: string

  @Prop({ type: [String, Number], required: false, default: null })
  days!: string | number

  @Prop({ type: Array, required: false, default: null })
  dayAnd7Days!: number[]

  @Prop({ type: String, required: false, default: null })
  createdAt!: string

  toFixed = toFixed

  get totalValue() {
    return (+this.lpValue || 0) + (+this.value || 0) + (+this.feeApy || 0)
  }

  get toShow() {
    if (!this.days) return true

    const daysBeforeNow = moment().subtract(this.days.toString(), 'd')
    const createdAt = moment(this.createdAt)

    return daysBeforeNow.isSameOrAfter(createdAt)
  }

  getBadgeClass(value: number) {
    return [
      'flex w-[min-content] space-x-1.5 items-center rounded-full font-medium',
      Math.abs(value) < 0.001 || !this.toShow
        ? 'text-gray-500'
        : value > 0
        ? 'text-success-1'
        : 'text-error-1',
    ]
  }
}
