
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

// eslint-disable-next-line no-use-before-define
@Component<TabsFilled>({
  beforeMount() {
    this.activeTab = this.activeTab ?? this.tabs[0]
  },
})
export default class TabsFilled extends Vue {
  @Prop({ type: Array, required: true }) tabs!: Array<string>
  @VModel({ type: String }) activeTab!: string
  @Prop({ type: Boolean }) leftRounded!: boolean
  @Prop({ type: Boolean }) rightRounded!: boolean
}
