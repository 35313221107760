import api from '~/api'
import { Pool } from '~/types/camelot'

let cache = {
  data: {} as Record<string, Pool>,
  date: 0,
}
let promise: ReturnType<typeof api.camelot> | null = null

export async function getCamelotPoolMap() {
  if (Date.now() - cache.date < 5 * 60 * 1000) {
    return cache
  }

  const result = await (promise = promise || api.camelot({ timeout: 15000 }))

  promise?.finally(() => (promise = null))

  return (cache = Object.values(result.nftPools).reduce(
    (map, e) => {
      map.data[e.depositToken.toLowerCase()] = e

      return map
    },
    { data: {}, date: Date.now() } as typeof cache
  ))
}
