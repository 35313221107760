
import 'vue-select/dist/vue-select.css'

import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import vSelect from 'vue-select'

import ProgressLoader from '../UI/ProgressLoader.vue'

import StarIcon from '~/components/Icons/StarIcon.vue'
import { CHAIN_ICONS, CHAIN_COLORS } from '~/constants/chainConfigs'
import {
  getRewardInfo,
  getLiquidityMiningConfig,
  liquidityAllianceAddress,
  getStrategiesAddress,
} from '~/constants/liquidityMining'
import { web3Store } from '~/store'
import { Pool as CamelotPool } from '~/types/camelot'
import { Pool } from '~/types/merkl'
import { getChainId, secondsInYear } from '~/utils'

let typeChanged = false

// eslint-disable-next-line no-use-before-define
@Component<StrategyTable>({
  components: { ProgressLoader, StarIcon, vSelect },

  created() {
    this.searchText = this.sortConfig.search ?? ''

    this.returnInToken =
      this.data.returnInToken || this.sortConfig.returnInToken || 'USD'

    typeChanged = true
  },
})
export default class StrategyTable extends Vue {
  @Prop({ type: Object, required: false, default: () => ({}) })
  data!: any

  @Prop({ type: Array, required: true }) types: any

  @Prop({ type: Boolean }) isLoading!: boolean
  @Prop({ type: Boolean, default: false }) hideSearch!: boolean
  @Prop({ type: Boolean, default: false }) leaderboard!: boolean
  @Prop({ type: Object, required: false, default: () => ({}) })
  strategyMap?: Record<string, Pool>

  @Prop({ type: Object, required: false, default: () => ({}) })
  strategyMapCamelot?: Record<string, CamelotPool>

  @PropSync('config', {
    type: Object,
    default: () => ({
      column: 'aum',
      direction: 'desc',
      search: '',
      addresses: undefined,
      type: null,
      returnInToken: 'USD',
    }),
  })
  sortConfig!: {
    column: string
    search: string
    direction: 'desc' | 'asc'
    type: string | null
    returnInToken: string | null
    addresses: string[] | undefined
  }

  CHAIN_ICONS = CHAIN_ICONS
  CHAIN_COLORS = CHAIN_COLORS

  get rewardInfo() {
    return getRewardInfo(getChainId(this.web3Store.network!))
  }

  get selectedType() {
    return !this.sortConfig.type
      ? this.sortConfig.addresses
        ? 'watch'
        : 'all'
      : ['eth', 'matic'].includes(this.sortConfig.type.toLowerCase())
      ? /(polygon)|(matic)/.exec(this.web3Store.network ?? '')
        ? 'matic'
        : 'eth'
      : this.sortConfig.type.toLowerCase()
  }

  web3Store = web3Store

  get returnTypes() {
    return [
      { value: 'USD', label: 'USD', logo: 'DollarIcon' },
      ...(this.leaderboard
        ? [
            // { value: 'MATIC', label: 'Matic', logo: 'PolygonIcon' },
            // { value: 'ETH', label: 'Eth', logo: 'EthIcon' },
          ]
        : [
            /(polygon)|(matic)/.exec(this.web3Store.network ?? '')
              ? { value: 'MATIC', label: 'Matic', logo: 'PolygonIcon' }
              : { value: 'ETH', label: 'Eth', logo: 'EthIcon' },
          ]),
      { value: 'BTC', label: 'BTC', logo: 'BitcoinIcon' },
    ]
  }

  get items(): any[] {
    return this.data.data ?? []
  }

  get lmStrategies() {
    return getStrategiesAddress(this.chainId)
  }

  searchText: string | null = this.sortConfig?.search ?? ''
  searchTimer: ReturnType<typeof window.setTimeout> | null = null
  returnInToken: string | null = 'USD'

  get columns() {
    return [
      { label: '', field: 'star' },
      ...(this.leaderboard ? [{ label: 'Rank', field: 'rank' }] : []),
      { label: 'Strategy', field: 'title' },
      { label: 'AUM', field: 'aum', dollarSign: true },
      { label: 'Share Price', field: 'sharePrice' },
      { label: 'Accu. Fees', field: 'fees' },
      // ...(this.leaderboard ? [] : [{ label: 'Vol.', field: 'volume' }]),
      {
        label: this.rewardInfo.active ? 'Total APR' : 'Fees APR',
        field: 'fees_apr',
        percentageSign: true,
      },
      { label: '24H', field: 'one_day_apy', percentageSign: true },
      { label: '7 Day', field: 'seven_day_apy', percentageSign: true },
      // {
      //   label: 'Since Inception',
      //   field: 'since_inception',
      //   percentageSign: true,
      // },
    ]
  }

  disableSortColumns = this.leaderboard
    ? this.columns.map((e) => e.field)
    : [
        'star',
        'rank',
        'assets',
        // 'fees',
        'fees_apr',
        'seven_day_apy',
        'one_day_apy',
        // 'since_inception',
        'status',
      ]

  _timer: any
  // @Watch('searchText')
  search(value: string = '') {
    clearTimeout(this._timer)
    this._timer = setTimeout(() => {
      const search = value.trim()
      this.sortConfig.search = search
      this._timer = null
      // if (this.sortConfig.search === search) {
      //   return
      // }

      // if (this.searchTimer) {
      //   clearTimeout(this.searchTimer)
      //   this.searchTimer = null
      // }

      // this.searchTimer = setTimeout(() => (this.sortConfig.search = search), 350)
    }, 250)
  }

  feeAPY(row: any) {
    if (!row.title.includes('+')) return 0

    const f = row.sharePrice - 100
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const secondsElapsed = currentTimestamp - 1689168229
    const rebasingAndFeeAPY =
      ((1 + f / 100) ** (secondsInYear / secondsElapsed) - 1) * 100
    return rebasingAndFeeAPY
  }

  getApr(id: string) {
    return this.getAprMerkl(id) || this.getAprCamelot(id)
  }

  getAprMerkl(id: string) {
    return this.strategyMap?.[id.toLowerCase()]?.meanAPR
  }

  getAprCamelot(id: string) {
    console.log(this.strategyMapCamelot?.[id.toLowerCase()])

    return this.strategyMapCamelot?.[id.toLowerCase()]?.maxIncentivesApr
  }

  @Watch('returnInToken')
  onReturnInTokenChange(type: string | null) {
    if (typeChanged) {
      typeChanged = false
      return
    }
    if (!type) this.sortConfig.returnInToken = this.returnInToken || 'USD'
    else this.sortConfig.returnInToken = type.toUpperCase()
  }

  get watchListedStrategy(): string[] | undefined {
    return this.chainId
      ? this.$store.state.app.watchListMap[this.chainId]
      : undefined
  }

  onTypeSelected({
    type: _type,
    currency: _currency,
  }: {
    type: string
    currency?: string | null
  }) {
    let addresses: string[] | undefined, type

    if (_type.toLowerCase() === 'watch') {
      addresses = this.watchListedStrategy
    } else if (_type.toLowerCase() === 'la') {
      addresses = this.lmStrategies
      type = _type.toUpperCase()
    } else {
      addresses = undefined
      if (_type.toLowerCase() === 'all') {
        type = undefined
      } else {
        type = _type.toUpperCase()
      }
    }
    console.log(_currency, this.returnInToken, 'pp')
    const currency =
      typeof _currency === 'undefined' ? this.returnInToken : _currency
    Object.assign(this.sortConfig, { type, addresses, returnInToken: currency })
    typeChanged = this.returnInToken !== currency
    this.returnInToken = currency || 'USD'
  }

  getChainId = getChainId

  hasLiquidityMining(row: any) {
    return !!getLiquidityMiningConfig(row.id, getChainId(row.network))?.address
  }

  isFromLiquidityAlliance(id: string) {
    return liquidityAllianceAddress.includes(id)
  }

  get chainId() {
    return this.web3Store.status.chainId!
  }

  sort(column: string) {
    if (this.disableSortColumns.includes(column)) return
    this.sortConfig = {
      column,
      type: this.sortConfig.type,
      addresses: this.sortConfig.addresses,
      search: this.sortConfig.search,
      returnInToken: this.sortConfig.returnInToken,
      direction:
        this.sortConfig.column === column
          ? this.sortConfig.direction === 'asc'
            ? 'desc'
            : 'asc'
          : this.sortConfig.column === 'title'
          ? 'asc'
          : 'desc',
    }
    // this.$store.commit('strategy/sort', this.sortConfig)
  }

  getBadgeClass(value: number) {
    return [
      'flex w-[min-content] bg-opacity-10 space-x-1 items-center px-[12px] py-2 rounded-full !text-[12px] leading-p160 font-medium',
      value === 0
        ? 'text-gray-500'
        : value > 0
        ? 'text-success-1'
        : 'text-error-1',
      value === 0 ? '' : value > 0 ? 'bg-success-1' : 'bg-error-1',
    ]
  }

  isWatchListed(address: string): boolean {
    return !!this.$store.state.app?.watchListMap[this.chainId]?.includes(
      address
    )
  }
}
