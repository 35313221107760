var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-white-background text-center rounded-lg font-inter p-1 overflow-hidden flex dark:bg-dark-400 dark:text-gray-400"},_vm._l((_vm.tabs),function(tab){return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(tab.message || undefined),expression:"tab.message || undefined"}],key:tab.name || tab,staticClass:"rounded-lg cursor-pointer font-medium flex-1 text-center text-sm py-2 px-4 lg:px-5 leading-[17px] capitalize whitespace-nowrap",class:[
      _vm.activeTab == (tab.value || tab.name || tab)
        ? 'bg-white text-black-mid dark:bg-grey-7 dark:text-grey-1 !cursor-default'
        : 'text-grey-6 dark:text-gray-400',
      { 'opacity-70 filter grayscale !cursor-not-allowed': tab.disabled },
    ],style:({
      filter:
        _vm.activeTab == (tab.value || tab.name || tab)
          ? 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.07));'
          : undefined,
    }),on:{"click":function($event){!tab.disabled &&
        (_vm.activeTab =
          tab.value !== undefined
            ? tab.value
            : tab.name !== undefined
            ? tab.name
            : tab)}}},[_vm._v("\n    "+_vm._s(tab.name || tab)+"\n  ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }