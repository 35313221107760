
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Abbreviate } from '~/plugins/filters'

@Component
export default class StatsGraph extends Vue {
  @Prop({ type: Object, required: true }) statItem!: {
    text: string
    value: number
    chartData: any
    breakdown: { value: number }[]
  }

  hovered: string | null = null

  abbreviate(v: string) {
    const a = Abbreviate(+v, 2)

    if (!/[.0-9]$/gi.test(a)) {
      return `$${a.slice(0, -1)}<small class="md:text-grey-5 md:dark:text-grey-6">${
        a[a.length - 1]
      }</small>`
    }

    return '$' + a
  }

  get isLightTheme() {
    return this.$colorMode.value === 'light'
  }
}
